<script setup lang="ts">
import type { PropType } from "vue"
import { formatForURL } from "~/composables/composables"
import type { Folder } from "~/types/folder"
import type { Subject } from "~/types/subject"

import { getColorClassNameFromScheme } from "~/utilities/colors"

const props = defineProps({
  chapters: {
    type: Object as PropType<Subject[]>,
    required: true,
    default: [] as Subject[],
  },
  folder: {
    type: Object as PropType<Folder>,
    required: true,
    default: () => ({}),
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const route = useRoute()
const router = useRouter()

const hash = computed(() => formatForURL(props.folder.title))
const isOpen = computed(() => {
  return route.hash.slice(1) === hash.value
})

function closeIfOpen(event: Event) {
  if (isOpen.value) {
    router.replace({ hash: "" })
    event.preventDefault()
  }
}

const backgroundVal = getColorClassNameFromScheme(
  props.folder.colorTag,
  ["bg"],
)
const background =
  backgroundVal === "white" || !backgroundVal.length
    ? "bg-purple-100"
    : backgroundVal

const setBackground = computed(() => {
  if (isOpen.value) return "bg-none"
  return background
})

const shadowLeftVal = getColorClassNameFromScheme(
  props.folder.colorTag,
  ["shadowLeft"],
)
const shadowLeft =
  shadowLeftVal === "white" || !shadowLeftVal.length
    ? "rgba(117, 90, 113, 1)"
    : shadowLeftVal

const shadowBottomVal = getColorClassNameFromScheme(
  props.folder.colorTag,
  ["shadowBottom"],
)
const shadowBottom =
  shadowBottomVal === "white" || !shadowLeftVal.length
    ? "rgba(31, 26, 30, 1)"
    : shadowBottomVal

const textColor = computed(() => {
  return getColorClassNameFromScheme(props.folder.colorTag, ["color"])
})

const isFolderEmpty = computed(() => {
  if (
    props.folder.mediatypes.articles.count === 0 &&
    props.folder.mediatypes.videos.count === 0 &&
    props.folder.mediatypes.quizes.count === 0
  ) {
    return true
  }
  return false
})
</script>

<template>
  <div
    v-if="
      (!isFolderEmpty && !route.hash) ||
      (isOpen &&
        route.fullPath.includes(route.fullPath.split('#')[1]))
    "
    class="relative"
  >
    <div
      :class="[
        {
          'btn-wrapper mb-20 ml-4': !isOpen,
        },
      ]"
    >
      <a
        :id="`folder-card-${folder.id}`"
        data-pendo="folder-card"
        class="sticky z-20 flex flex-col justify-between h-full gap-4 px-12 py-8 overflow-hidden shadow-xl xl:gap-10 xl:px-16 xl:py-10"
        :class="[
          setBackground,
          {
            'pl-[10px] pt-[24px] md:py-0': isOpen,
            ' ': !isOpen,
            'min-h-[250px] xl:min-h-[465px]':
              !folder.img.src.length && !isOpen,
          },
          ,
        ]"
        :href="`#${formatForURL(folder.title)}`"
        @click="closeIfOpen($event)"
      >
        <h2
          :id="formatForURL(folder.title)"
          :class="{
            'mb-2': isOpen,
          }"
          :style="{
            color: isOpen
              ? 'var(--color-platform-paper)'
              : `var(${textColor})`,
          }"
          class="mb-10 line-clamp-2 w-fit font-national2compressed text-4xl font-bold uppercase leading-none text-white md:text-5xl lg:text-6xl xl:text-[96px]"
        >
          {{ folder.priority }}
          <span
            v-if="folder.titleHtml"
            class="inline"
            v-html="folder.titleHtml"
          />
          <span v-else>{{ folder.title }}</span>
        </h2>
        <div
          v-if="!isOpen"
          class="flex flex-col-reverse gap-3 xl:flex-row xl:items-end xl:gap-14"
          :class="{ 'content-wrapper': folder.img.src.length }"
        >
          <img
            v-if="folder.img.src"
            :src="folder.img.src"
            :alt="folder.img.alt"
            class="w-96 xl:w-64 2xl:w-96"
          />
          <u-media-types
            :has-color-schema="props.folder.colorTag.length > 0"
            :data="folder"
            :style="{ color: `var(${textColor})` }"
          />
        </div>
      </a>
      <ul v-if="isOpen" class="pt-4">
        <li
          v-for="(chapter, key) in chapters"
          :id="`chapter-card-list-item-${chapter.id}`"
          :key="key"
          class="mb-10 lg:mb-14"
        >
          <UChapterCard :chapter="chapter" :licence="licence" />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.btn-wrapper {
  &:before {
    content: "";
    background-color: v-bind("shadowLeft");
    transform: skewY(-40deg);
    @apply absolute left-[-13px] top-[0.78rem] h-[calc(100%+1px)] w-[29px];
  }

  &:after {
    content: "";
    transform: skew(-50deg);
    background-color: v-bind("shadowBottom");
    width: calc(100% - 16px);
    @apply absolute bottom-[-25px] left-[1.4px] z-10 h-[25px];
  }
}
</style>
