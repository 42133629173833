import { acceptHMRUpdate, defineStore } from "pinia"
import type { Ref } from "vue"
import type { UILoadScreen } from "~/types/ui"

export const useUiStore = defineStore("ui", () => {
  /** State */
  const loader = ref({}) as Ref<UILoadScreen>
  const screenWidth = ref(0) as Ref<number>

  function load(options?: {
    active: boolean
    text?: string
    icon?: string
  }) {
    Object.assign(loader.value, options)
  }

  return {
    /** State */
    loader,
    screenWidth,
    /** Actions */
    load,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUiStore, import.meta.hot))
