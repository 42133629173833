import * as Sentry from "@sentry/vue"
import { api } from "~/axios"

interface KeyVal {
  userId: string
  key: string
  value: unknown
}

export async function set(
  key: string,
  value: unknown,
  userId?: string,
): Promise<void> {
  if (!userId) return
  try {
    const data: KeyVal = {
      userId: userId,
      key: key,
      value: JSON.stringify(value),
    }
    await api.external.put(`/rest/frontend/metadata/${key}`, data)
  } catch (error) {
    Sentry.captureException(error)
  }
}

export async function get(key: string): Promise<unknown> {
  try {
    const data = await api.external.get(
      `/rest/frontend/metadata/${key}`,
    )
    if (!data.data?.value) {
      const axiosError = data as unknown as {
        response: { status: number }
      }
      if (axiosError.response.status !== 404) {
        throw new Error(
          `Error fetching key value: ${JSON.stringify(axiosError)}`,
        )
      }
      return undefined
    }
    return JSON.parse(data.data.value)
  } catch (error) {
    Sentry.captureException(error)
    return undefined
  }
}
