<script setup lang="ts">
import { computed, PropType } from "vue"
import { ProductsList } from "~/models/Content/ContentProducts"
import {
  getProductVariantPriceLabel,
  getProductVariantPrice,
} from "~/utilities/getProductVariantPrice"
import UChooseInstitute from "./UChooseInstitute.vue"

const props = defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: true,
  },
  viewType: {
    type: String as PropType<"list" | "detail">,
    default: "list",
  },
  align: {
    type: String as PropType<"center" | "left">,
    default: "left",
  },
})

const router = useRouter()
const showVariantModal = ref(false)
provide("showVariantModal", showVariantModal)

function goToProductContentButtonHandler() {
  if (props.product.variants.length === 1) {
    router.push(
      `/produkter/${props.product.productKey ?? "exphil"}/${
        props.product.variants[0].variantKey
      }`,
    )
  } else {
    showVariantModal.value = true
  }
}

const hasValidLicence = computed(() => {
  return props.product.variants.some(p => p.userHasLicence)
})

const productVariantPrice = computed(() => {
  return getProductVariantPrice(props.product)
})
const productVariantPriceLabel = computed(() => {
  return getProductVariantPriceLabel(props.product)
})

const productReleased = computed(() => {
  return props.product.variants.some(p => p.isReleased)
})
</script>

<template>
  <div>
    <UChooseInstitute
      :product="product"
      :show-variant-modal="showVariantModal"
    />
    <div
      class="mt-5 flex flex-wrap gap-y-12 lg:justify-between"
      :class="{
        'flex-col  items-center justify-center': align === 'center',
        'flex-col': viewType === 'detail',
      }"
    >
      <div
        v-if="
          product.hasAtLeastOneReleasedProduct &&
          productVariantPrice !== undefined &&
          productVariantPriceLabel
        "
        class="flex grow items-center gap-2 text-3xl font-extrabold"
      >
        <div
          :class="{
            'text-[--color-dark-blue-translucent-dark]':
              viewType === 'list',
            'text-dark': viewType === 'detail',
          }"
        >
          {{ productVariantPriceLabel }}
        </div>
        <div
          :class="{
            'text-[--theme-translucent-dark]': viewType === 'list',
            'text-dark': viewType === 'detail',
          }"
        >
          {{ productVariantPrice ? productVariantPrice : 0 }},-
        </div>
      </div>
      <div
        v-else-if="viewType === 'list'"
        class="flex grow items-center italic text-[--theme-dark]"
      >
        <p class="text-3xl font-extrabold">Kommer snart</p>
      </div>
      <div class="flex flex-wrap items-center gap-4">
        <UButtonNew
          v-if="viewType === 'list'"
          bold
          size="md"
          :to="`/produkter/${product.urlFormatProductTitle}`"
        >
          Les mer

          <span class="sr-only"
            >om Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>
        <UButtonNew
          v-if="
            productReleased &&
            (hasValidLicence ||
              viewType === 'detail' ||
              product.variants.some(v => v.isFree))
          "
          bold
          :pill="viewType !== 'detail'"
          :theme="viewType !== 'detail' ? 'cta' : undefined"
          size="md"
          :variant="viewType !== 'detail' ? 'filled' : undefined"
          @click="goToProductContentButtonHandler"
        >
          {{ !hasValidLicence ? "Prøv gratis" : "Til produktet" }}

          <span class="sr-only"
            >om Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>

        <UButtonNew
          v-if="
            product.hasAtLeastOneReleasedProduct &&
            product.variants.some(v => !v.isFree) &&
            !hasValidLicence
          "
          variant="filled"
          theme="cta"
          pill
          size="md"
          data-pendo="forside-kjop-tilgang"
          bold
          :to="`/produkter/${product.urlFormatProductTitle}/betaling`"
        >
          Kjøp
          <span class="hidden sm:inline">tilgang</span>
          <span class="sr-only"
            >til Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>
      </div>
    </div>
  </div>
</template>
