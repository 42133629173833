import type { MarketingArticleContent } from "../../types/MarketingArticle"
import GraphqlMarketingArticleDocument from "../../graphql/documents/marketingArticle"

import { graphqlClient } from "../../graphql/client"

export default async (
  urlAlias: string,
): Promise<MarketingArticleContent | null | undefined> => {
  const { data } = await graphqlClient({
    query: GraphqlMarketingArticleDocument,
    variables: {
      urlAlias,
    },
  })

  if (!data) return null

  const query = data._repository.location.content

  if (!query) return null

  const { _content, title, subtitle, tags } = query

  const flattenedContent = _content.locations[0].children.edges.map(
    ({ node: { content } }) => ({
      sectionBody: content.sectionBody,
      sectionBodyIsAQuote: content.sectionBodyIsAQuote,
      sectionTitle: content.sectionTitle,
      image: content.image?.variation?.uri,
      imageText: content.image?.alternativeText,
      buttonText: content.buttonText,
      buttonUrl: content.buttonUrl,
    }),
  )

  return {
    content: flattenedContent,
    title,
    subtitle,
    tags,
  }
}
