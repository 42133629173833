import type { BaseItem } from "~/models/Content/BaseItem"
import type { ContentImage } from "~/models/Content/ContentImage"
import type { ContentSvg } from "~/models/Content/ContentSvg"
import type { SortField, SortOrder } from "~/models/Content/Sort"
import type { Criterion } from "~/models/Search"
import type { SearchResponse } from "~/models/Content/Response"
import { Subtree } from "~/models/Content/Subtree"
import { ContentType } from "~/models/Content/ContentType"
import arrayUtils from "~/utilities/arrayUtils"
import { useSearch } from "~/composables/useSearch"
import useContentMapper from "~/composables/useContentMapper"
import useSearchHelper from "~/composables/useSearchHelper"

/**
 * You should probably not extend this API.
 *
 * After the dimensional merge, where Content and ResourceItem are merged into the same
 * reality, there will be less and less need for a dedicated Content API. Instead, you
 * should probably look into extending the Resource and Section APIs.
 */
export default function useContentApi() {
  const { mapContents } = useContentMapper()
  const { searchPath, emptyQuery, cardFields } = useSearchHelper()
  const { truthy } = arrayUtils()

  const findContents = async <T extends BaseItem>(
    criterions: Criterion,
    limit: number = 100,
    offset: number = 0,
    fields: string[] = [],
  ): Promise<T[]> => {
    const transformData = (response: SearchResponse) =>
      mapContents(response.View.Result)
    const { fetchResults, results } = useSearch<T[]>(searchPath, {
      transformData,
      debounceDuration: 0,
    })
    await fetchResults(
      { ...emptyQuery, fields },
      criterions,
      limit,
      offset,
    )
    return [...(results.value ?? [])]
  }

  const findChildren = async (
    parentLocationIds: number[],
    contentTypeIdentifiers: ContentType[] = [],
    sortField: SortField = "priority",
    sortOrder: SortOrder = "asc",
    extraFields: string[] = [],
  ): Promise<BaseItem[]> => {
    if (!parentLocationIds.filter(truthy).length) {
      console.warn("Missing parentLocationIds in getChildren")
      return []
    }

    return await findContents<BaseItem>(
      {
        parentLocationIdCriterion: parentLocationIds,
        contentTypeCriterion: contentTypeIdentifiers,
        sortField,
        sortOrder,
      },
      100,
      0,
      [...cardFields, ...extraFields],
    )
  }

  const findImageContent = async (
    contentId: number,
  ): Promise<ContentImage | undefined> => {
    return (
      await findContents<ContentImage>(
        {
          contentIdCriterion: [contentId],
          contentTypeCriterion: [ContentType.Image],
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          mainLocationCriterion: true,
        },
        1,
        0,
      )
    )[0]
  }

  const findSvgContent = async (
    contentId: number,
  ): Promise<ContentSvg | undefined> => {
    return (
      await findContents<ContentSvg>(
        {
          contentIdCriterion: [contentId],
          contentTypeCriterion: [ContentType.Svg],
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          mainLocationCriterion: true,
        },
        1,
        0,
      )
    )[0]
  }

  return {
    findContents,
    findImageContent,
    findSvgContent,
    findChildren,
  }
}
