<script setup lang="ts">
import UProductPageBlock from "~/components/Marketing/UProductPageBlock.vue"
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"
import UProductPageBlockQuote from "~/components/Marketing/UProductPageQuote.vue"
import {
  baseSellingPoints,
  additionalSellingPoints,
  videoProducts,
} from "../utilities/sellingPoints"

const data = useProductVariantsStore()
const productBlocks = ref<ContentProductPageBlocks[] | undefined>()

watchEffect(() => {
  if (data.product && data.marketingData) {
    productBlocks.value =
      data.marketingData[data.product]?.productPageBlocks
  }
})
const heroSectionMounted = ref(false)
const style = getComputedStyle(document.body)
style.getPropertyValue("--theme-loaded")

const sellingPoints = computed(() => {
  const productKey = data.activeProduct?.productKey

  const additionalFeatures =
    productKey === "exphil"
      ? Object.values(additionalSellingPoints)
      : videoProducts.includes(productKey ?? "")
        ? [additionalSellingPoints.video]
        : []

  return [
    baseSellingPoints[0],
    ...additionalFeatures,
    baseSellingPoints[1],
  ]
})

const containerClass =
  "flex flex-col items-center justify-center px-8 text-xl md:px-24"
</script>

<template>
  <div class="flex flex-col gap-14 text-[--theme-darkest] lg:gap-24">
    <div :class="containerClass">
      <UProductPageHero @mounted="heroSectionMounted = true" />
    </div>

    <UProductPageBlockQuote
      v-if="data.product"
      :text="data?.marketingData?.[data.product]?.quote"
    />
    <UProductPagePensum />
    <div :class="containerClass">
      <USellingPoints
        :selling-points="sellingPoints"
        :bg-color="'bg-[--theme-dark]'"
        :text-color="'text-[--theme-lightest]'"
      />
    </div>
    <div
      v-if="heroSectionMounted && productBlocks"
      class="gap-14 lg:gap-24"
      :class="containerClass"
    >
      <UProductPageBlock
        v-for="productBlock in productBlocks"
        :key="productBlock.contentId"
        img-text-color="white"
        :product-block-data="productBlock"
      />
    </div>
    <UProductPageHero
      position="bottom"
      class="mt-24 py-24 md:px-24"
    />
  </div>
</template>

<route lang="yaml">
name: productPage
meta:
  layout: productPage
</route>
