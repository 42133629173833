import type { Ref } from "vue"
import type { Article } from "~/types/article"
import type { Subject } from "~/types/subject"
import type { PageData, PageUrlAlias } from "~/types/pages"

import { acceptHMRUpdate, defineStore } from "pinia"

import getPageDataFormat from "~/hooks/page"
import getPageNavDataFormat from "~/hooks/page/pages"
import getArticlesDataFormat from "~/hooks/article"
import { getRestrictedArticles } from "~/hooks/restricted-article"
import { FOLDER_REGEX } from "~/utilities/constants"
import getMarketingArticleDataFormat from "~/hooks/marketingArticle"

export const useDataStore = defineStore("data", () => {
  /** State */

  const pages: Ref<PageData[]> = ref([]) // Todo -> Add types for pages
  const pageNav: Ref<PageUrlAlias[]> = ref([])
  const subjects: Ref<Subject[]> = ref([])
  const articles: Ref<Article[]> = ref([])

  /** Getters */
  const subjectNav = computed(() =>
    subjects.value
      .map(i => {
        return {
          id: i.id,
          parentLocationId: i.parentLocationId,
          text: i.title,
          hrefRaw: i.href,
          href: i.href.replace(FOLDER_REGEX, ""),
          priority: i.priority,
          hidden: i.hidden,
          totalChildren: i.totalChildren,
          type: i.type,
        }
      })
      .sort((a, b) => a.priority - b.priority),
  )

  const articleNav = computed(() =>
    articles.value.map(i => {
      return {
        id: i.id,
        parentLocationId: i.parentLocationId,
        text: i.title,
        hrefRaw: i.href,
        href: i.href.replace(FOLDER_REGEX, ""),
        priority: i.priority,
        hidden: i.hidden,
        restricted: i.restricted || false,
      }
    }),
  )

  /**
   * Get data for the landing page
   * @param urlAlias
   */
  async function getPage(urlAlias: string) {
    const page = await getPageDataFormat(urlAlias)

    if (!page) return

    const index = pages.value.findIndex(item => item.id === page.id)
    if (index !== -1) pages.value.splice(index, 1, page)
    else pages.value.push(page)

    return page
  }

  async function getMarketingArticles(urlAlias: string) {
    const marketingArticles =
      await getMarketingArticleDataFormat(urlAlias)

    if (!marketingArticles) return

    return marketingArticles
  }

  async function getPageNav() {
    const pageNavs = await getPageNavDataFormat()

    pageNav.value = pageNavs

    return pageNavs
  }

  /**
   * Get all articles by location id.
   * Used for restricted articles.
   * @param locationId
   */

  async function getArticlesByLocationId(locationId: number) {
    NProgress.start()

    try {
      const restrictedArticlesRaw =
        await getRestrictedArticles(locationId)
      const restrictedArticles = restrictedArticlesRaw.map(
        (e: any) => ({
          audio: undefined,
          bg: "platform-paper",
          body: {
            html: `<h1>${e.name}</h1><div class="article-text-placeholder"></div>`,
          },
          color: "black",
          href: e.url,
          id: e.locationId,
          imageCaption: { html: "" },
          img: e.image,
          intro: { html: "\n" },
          parentLocationId: locationId,
          priority: e.priority,
          shortTitle: "",
          template: e.template || "UImageWithTextArticle",
          title: e.name,
          type: "article",
          restricted: true,
        }),
      )

      articles.value = restrictedArticles
      NProgress.done()
    } catch (error) {
      NProgress.done()
      throw error
    }
  }

  const formatSubjectPage = (
    parentId: string | number | undefined,
  ): Article | undefined => {
    const subject = subjects.value.find(s => s.id === parentId)

    if (!subject) return
    const {
      img,
      id,
      parentLocationId,
      title,
      shortTitle,
      subjectPageTitlePosition,
      colorTag,
      href,
      priority,
      // introPages
    } = subject
    if (subject.introPages && subject.introPages.length > 0) {
      return {
        id: (id as number) + parentLocationId,
        parentLocationId: id as number,
        template: "USlidesArticle",
        bg: "platform-paper",
        img,
        title,
        shortTitle,
        priority,
        subjectPageTitlePosition,
        // introPages,
        colorTag,
        href: `${href}/forside`,
        isDark: false,
      }
    }
  }

  /**
   * Get all articles by product name + subject name ("path/to/subject")
   * @param path
   */
  async function getArticles(args: {
    product: string
    variant: string
    subject: string
    id?: number
  }) {
    let currentSubject = subjects.value.find(
      i =>
        i.href === `/${args.product}-${args.variant}/${args.subject}`,
    )

    let currentLocationId = currentSubject?.id

    if (!currentSubject && args.id) {
      currentSubject = subjects.value.find(i => i.id === args.id)
      currentLocationId = args.id
    }

    if (!currentSubject) return

    NProgress.start()

    const inStore = articles.value.find(
      a => a.parentLocationId === currentSubject?.id,
    )

    if (inStore) {
      NProgress.done()
      return
    }

    if (currentSubject?.totalChildren === 0) {
      await getArticlesByLocationId(currentLocationId as number)
      return
    }

    try {
      const items = await getArticlesDataFormat(
        currentLocationId as number,
      )
      const subjectPage = formatSubjectPage(currentLocationId)

      if (subjectPage) items.unshift(subjectPage)

      items?.map((i, key, arr) => {
        const index = articles.value.findIndex(
          item => item.id === i.id,
        )
        if (index !== -1) articles.value.splice(index, 1, i)
        else articles.value.push(i)

        if (key === arr.length - 1) {
          NProgress.done()
        }
      })
    } catch (error) {
      NProgress.done()
      throw error
    }
  }

  return {
    /** State */
    pages,
    pageNav,
    subjects,
    articles,
    /** Getters */
    subjectNav,
    articleNav,
    /** Actions */
    getPage,
    getPageNav,
    getArticles,
    getArticlesByLocationId,
    getMarketingArticles,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useDataStore, import.meta.hot),
  )
