import type { ContentProductPages } from "~/models/Content/ContentProductPages"
import { formatForURL } from "~/composables/composables"
import useContentApi from "~/api/contentApi"
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"

const PRODUCTS_PAGES_LOCATION_ID = import.meta.env
  .VITE_CMS_PRODUCTS_PAGES_LOCATION_ID as string | "0"

const getContent = useContentApi().findContents

export default async (): Promise<ContentProductPages | null> => {
  const result = await getContent<ContentProductPages>({
    contentTypeCriterion: ["product_page"],
    parentLocationIdCriterion: [parseInt(PRODUCTS_PAGES_LOCATION_ID)],
  })

  for (const p of result) {
    const productPageBlocks =
      await getContent<ContentProductPageBlocks>({
        contentTypeCriterion: ["product_page_block"],
        parentLocationIdCriterion: [p.locationId],
        sortField: "priority",
      })

    if (productPageBlocks && productPageBlocks.length > 0) {
      p.productPageBlocks = productPageBlocks
    }
  }

  const resultFormatted = result.reduce((acc, page) => {
    const key = formatForURL(
      page.title.replaceAll(" ", "-").toLowerCase(),
    )
    acc[key] = page
    return acc
  }, {}) as ContentProductPages

  return resultFormatted
}
