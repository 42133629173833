<script lang="ts">
import type { CSSProperties } from "vue"
import { computed, defineComponent, h, useSlots } from "vue"
import useSkeletor from "./composables"
import { convertToUnit } from "./helpers"

export default defineComponent({
  name: "Skeletor",

  props: {
    as: {
      type: String,
      default: "span",
    },

    circle: {
      type: Boolean,
      default: false,
    },

    pill: {
      type: Boolean,
      default: false,
    },

    size: {
      type: [String, Number],
      default: null,
    },

    width: {
      type: [String, Number],
      default: null,
    },

    height: {
      type: [String, Number],
      default: null,
    },

    shimmer: {
      type: Boolean,
      default: undefined,
    },
  },

  setup(props) {
    const skeletor = useSkeletor()! as { shimmer: unknown }

    const slots = useSlots()

    const isRect = computed(
      () => !props.circle && (props.size || props.height),
    )

    const isText = computed(
      () => !props.circle && !props.size && !props.height,
    )

    const isShimmerless = computed(() =>
      props.shimmer !== undefined
        ? !props.shimmer
        : !skeletor.shimmer,
    )

    const classes = computed(() => ({
      skeleton: true,
      "skeleton--rect": isRect.value,
      "skeleton--text": isText.value,
      "skeleton--shimmerless": isShimmerless.value,
      "skeleton--circle": props.circle,
      "skeleton--pill": props.pill,
    }))

    const style = computed(() => {
      const _style: CSSProperties = {}

      if (props.size) {
        const size = convertToUnit(props.size)
        _style.width = size
        _style.height = size
      }

      if (!props.size && props.width) {
        _style.width = convertToUnit(props.width)
      }

      if (!props.size && props.height) {
        _style.height = convertToUnit(props.height)
      }

      return _style
    })

    const children = isText.value
      ? slots.default
        ? slots.default()
        : "\u200C"
      : null

    return () =>
      h(
        props.as,
        {
          class: classes.value,
          style: style.value,
        },
        [children],
      )
  },
})
</script>

<style scoped lang="scss">
.skeleton {
  position: relative;
  overflow: hidden;

  &:not(&--shimmerless) {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.3),
        rgba(37, 22, 22, 0)
      );
      animation: shimmer 1.5s infinite;
      content: "";
    }
  }

  &--rect,
  &--circle {
    display: block;
  }

  &--circle {
    border-radius: 50%;
  }

  &--pill,
  &--text {
    // border-radius: 9999px;
  }

  &--text {
    line-height: 1;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
./helpers/helpers./composables/composables
