import { InjectionKey, Ref } from "vue"
import { MathTask } from "~/types/article"
import { AssignmentSolutions } from "~/hooks/math-task"

export const keys = {
  suggestedSolution: Symbol("suggestedSolution") as InjectionKey<
    Ref<AssignmentSolutions | undefined>
  >,
  mathTask: Symbol("mathTask") as InjectionKey<
    Ref<MathTask | undefined>
  >,
}
