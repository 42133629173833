<script setup>
import { downloadProgress } from "~/composables/offlineDownload"
const dataStore = useProductContentStore()

const imageArticles = computed(() => {
  return dataStore.articles.filter(article => article.img)
})

// const videoArticles = computed(() => {
//   return dataStore.articles.filter(article => article.video)
// })
</script>

<template>
  <div
    v-if="downloadProgress.articlesDownloadedStatus === 'finished'"
    class="invisible h-0"
  >
    <div v-for="article in imageArticles" :key="article.id">
      <div v-if="article.img?.src">
        <img :src="article.img?.src" />
      </div>
    </div>

    <!-- <div v-for="article in videoArticles" :key="article.id">
      <div v-if="article.video">
        <video
          :src="article.video.metadata.elementURI"
          preload="auto"
          crossorigin="anonymous"
        ></video>
      </div>
    </div> -->
  </div>
</template>
