<script setup lang="ts">
import { ProductsList } from "~/models/Content/productVariant"

const products = useProductVariantsStore()

const productVariantsByStatus = computed(() => {
  const productVariants = products.productsList.sort((a, b) =>
    a.productTitle.localeCompare(b.productTitle),
  )

  const productVariantsStatus: {
    comingSoon: ProductsList[]
    earlyAccess: ProductsList[]
    released: ProductsList[]
  } = {
    comingSoon: [],
    earlyAccess: [],
    released: [],
  }

  for (const product of productVariants) {
    // We only include products with EAN on front page
    if (product.variants.some(variant => variant.ean)) {
      if (!product.hasAtLeastOneReleasedProduct) {
        productVariantsStatus.comingSoon.push(product)
      } else if (product.hasAtLeastOneReleasedProduct) {
        productVariantsStatus.released.push(product)
      }
    }
  }

  return productVariantsStatus
})
</script>

<template>
  <div class="space-y-16">
    <section>
      <h3
        id="released-products"
        class="mb-7 font-national2condensed text-[32px] font-medium md:text-5xl"
      >
        Våre produkter
      </h3>
      <ul aria-labelledby="released-products">
        <li
          v-for="released in productVariantsByStatus.released"
          :key="released.productTitle"
          class="pb-8 last:pb-0"
        >
          <UProductCard :product="released" />
        </li>
      </ul>
    </section>
    <section v-if="productVariantsByStatus.comingSoon.length > 0">
      <h3
        id="coming-soon-products"
        class="mb-7 font-national2condensed text-4xl font-medium md:text-5xl"
      >
        Kommer snart
      </h3>
      <ul aria-labelledby="coming-soon-products">
        <li
          v-for="comingSoon in productVariantsByStatus.comingSoon"
          :key="comingSoon.productTitle"
          class="pb-8 last:pb-0"
        >
          <UProductCard :product="comingSoon" :released="false" />
        </li>
      </ul>
    </section>
  </div>
</template>
