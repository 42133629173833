import { ref } from "vue"
import hashString from "~/utilities/hash"
const { user } = useAuth()

export const isFeatureEnabled = ref(
  localStorage.getItem("isFeatureEnabled") === "true",
)

export const setupFeatureFlag = () => {
  const personalization = usePersonalizationStore()

  watch(
    () => personalization.settings,
    async settings => {
      if (settings.featureFlagAccess === true) {
        isFeatureEnabled.value = true
      }
    },
    { immediate: true, deep: true },
  )

  watch(
    () => isFeatureEnabled.value,
    value => {
      const personalization = usePersonalizationStore()
      localStorage.setItem("isFeatureEnabled", value.toString())
      personalization.settings.featureFlagAccess = value
    },
  )

  watch(
    user,
    async (newUser, oldUser) => {
      if (!oldUser?.uid && newUser.uid) {
        const allowedUsers = [
          "c53a059a3e8a9d846890828083e3726255a7d091c84dfd38f2208f38715fa9c6", // In
          "d9608c78c8f426d9d6330eaf770c8f717ff9ee6cd35506696994df4890a4938f", // test user
        ]

        const emmailHash = await hashString(newUser.email ?? "")
        if (allowedUsers.includes(emmailHash)) {
          isFeatureEnabled.value = true
        }
        if (personalization.settings.featureFlagAccess === true) {
          isFeatureEnabled.value = true
        }
      }
    },
    { immediate: true },
  )
}
