<script setup lang="ts">
import { computed, Prop, PropType, ref } from "vue"
import { KsSkeleton, KsSkeletonWrapper } from "@aschehoug/kloss"
import "@aschehoug/kloss/dist/index.css"

defineProps({
  position: {
    type: String as PropType<"top" | "bottom">,
    default: "top",
  },
})

const route = useRoute<"product">()

const products = useProductVariantsStore()
const groupedProductVariants = products.product
  ? products.groupedProductVariants?.[products.product]
  : undefined

const productInformation = useProductVariantsStore()

// Get product from ibexa
const ibexaProductEAN = computed(() => {
  const foundProduct = products?.productsList?.find(product =>
    product.productKey.includes(route.params.product),
  )

  return foundProduct ? [foundProduct] : []
})

//Ibexa product filtered by EAN
const productData = computed(() => {
  const ibexaProduct = ibexaProductEAN.value?.[0]
  const ean = ibexaProduct?.ean
  return ean
    ? groupedProductVariants?.variants.find(v => v.ean?.includes(ean))
    : undefined
})

//Different product states
const productReleased = computed(() => {
  if (products.activeVariant) {
    return products.activeVariant.isReleased
  } else {
    return products.productVariants.some(p => p.isReleased)
  }
})

const getProductImage = () => {
  return productInformation.activeProduct?.variants[0]?.eCommerce
    ?.imageUrl
}

const licenceImgDesc = computed(() => {
  const productVariant = productData.value
  return productVariant?.eCommerce?.imageDescription
})
const windowAvailWidth = ref(window.screen.availWidth)

window.addEventListener("resize", () => {
  windowAvailWidth.value = window.screen.availWidth
})

const imgTimeout = ref(false)
setTimeout(() => {
  if (!imgTimeout.value) {
    imgTimeout.value = true
  }
}, 5000)

const emit = defineEmits(["mounted"])
onMounted(() => {
  emit("mounted")
})
onBeforeMount(() => {
  window.scrollTo(1, 0)
})
</script>

<template>
  <section
    class="flex w-full items-center justify-center"
    :class="{ 'bg-[--theme-lighter]': position === 'bottom' }"
  >
    <div
      class="max-w-[90em]"
      :class="{
        'grid-cols-2 place-items-center  gap-20 lg:grid':
          position === 'top',
        'flex items-center justify-center': position === 'bottom',
      }"
    >
      <figure
        v-if="position === 'top'"
        class="order-2 flex h-fit items-center justify-center md:w-full lg:max-w-[40vw]"
      >
        <img
          v-if="getProductImage()"
          loading="eager"
          :src="getProductImage()"
          class="h-full max-h-[35em] w-fit max-w-full rounded-lg object-contain lg:max-h-full"
          :class="{
            'rounded-2xl': !getProductImage(),
          }"
          :alt="licenceImgDesc"
        />
        <KsSkeletonWrapper
          v-else-if="!imgTimeout"
          class="skeleton-wrapper h-full w-full rounded-2xl bg-[--theme-lightest]"
        >
          <KsSkeleton class="lg:max-w-[50rem]" height="34em" />
        </KsSkeletonWrapper>
      </figure>
      <div
        class="gird px-4"
        :class="{
          'text-center ': position === 'bottom',
          'text-left ': position === 'top',
        }"
      >
        <div v-if="position === 'top'">
          <h2
            class="font-numeraWebRegular text-marketing-mobile-h2-h2-caption text-[--theme-darkest] md:text-marketing-desktop-h2-h2-caption xl:gap-10"
            v-html="products.activeProduct?.header"
          ></h2>

          <h3
            v-if="!productReleased"
            class="font-national2condensed text-4xl font-medium text-[--theme-dark]"
          >
            Produktet lanseres snart, da kan du prøve oss helt gratis
          </h3>
        </div>

        <UProductActions
          :product="products.activeProduct"
          view-type="detail"
          :align="position === 'bottom' ? 'center' : 'left'"
        />
      </div>
    </div>
  </section>
</template>
