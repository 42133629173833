import { MaybeRef } from "@vueuse/core"
import { Article } from "~/types/article"
import {
  getMathTaskSolutions,
  isMathTemplate,
} from "~/hooks/math-task/mathTasks"
import { AssignmentSolutions } from "~/hooks/math-task"
import { keys } from "~/utilities/injection"

export function useMathTask(articleData: MaybeRef<Article>) {
  const data = ref<AssignmentSolutions | undefined>()

  watch(
    toRef(articleData),
    async article => {
      if (article.mathTask && isMathTemplate(article.template)) {
        data.value = await getMathTaskSolutions(article.id)
      }
    },
    { immediate: true },
  )

  const mathTask = computed(() => toRef(articleData).value.mathTask)

  provide(keys.mathTask, mathTask)
  provide(keys.suggestedSolution, data)

  return data
}
