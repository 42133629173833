<script setup lang="ts">
import { watch } from "vue"

const productContent = useProductContentStore()
const personalization = usePersonalizationStore()

watch(
  () => productContent.activeArticle,
  () => {
    personalization.activeArticle = productContent.activeArticle
  },
  { immediate: true },
)

onUnmounted(() => {
  personalization.activeArticle = undefined
})
</script>

<template>
  <div>
    <u-dynamic-article
      :data="productContent.activeArticle"
      :preview="false"
    />
    <div class="fixed right-0 top-0">
      <u-feature-flag>
        <div
          class="bg-platform-paper font-national2compressed text-sm text-black"
        >
          {{ personalization.elapsedSeconds }} s
        </div>
      </u-feature-flag>
    </div>
  </div>
</template>

<route lang="yaml">
name: article
meta:
  layout: article
  is: article
  authorize: true
</route>
