import type { RequestScopes } from "@aschehoug/aschehoug-oidc-client"
import { AschehougOidcClient } from "@aschehoug/aschehoug-oidc-client"
import type { Router } from "vue-router"
import type {
  JWTTokenData,
  ResetPasswordForm,
  SignupForm,
  User,
  UserActivationForm,
} from "~/types/auth"
import createStandardCookieDeleteUrl from "~/utilities/clean-cookies"

export const client = new AschehougOidcClient({
  authority: import.meta.env.VITE_ID_AUTHORITY,
  client_id: import.meta.env.VITE_ID_CLIENTID,
  scope: "openid profile email" as RequestScopes.openIdProfile,
  redirect_uri: location.origin,
  post_logout_redirect_uri: location.origin,
  redirect_feide_uri: "",
  response_type: "code",
  metadata: {
    authorization_endpoint: import.meta.env.VITE_ID_AUTHENDPOINT,
  },
  base_uri_am: import.meta.env.VITE_ID_BASEURL,
})

export const user = ref<Partial<User>>({})
export const isAuthorized = computed(
  () => user.value.uid !== undefined,
)
const sessionStatus = ref<string>("")

export async function init(router: Router) {
  sessionStatus.value = await client.startAuthSession()

  const comingFromCookieDeletion =
    localStorage.getItem("returning-from-deleting-cookies") === "true"
  if (comingFromCookieDeletion) {
    localStorage.removeItem("returning-from-deleting-cookies")
    const url = createVippsLoginUrl()
    location.href = url
    return
  }

  const fromPath = localStorage.getItem("vipps-redirect-from-path")
  if (fromPath) {
    localStorage.removeItem("vipps-redirect-from-path")
    router.push(fromPath)
  }

  const { value: route } = router.currentRoute
  if (route.meta?.loggedInRedirect) {
    if (route.params.product && route.params.variant) {
      router.push(
        `/produkter/${route.params.product}/${route.params.variant}`,
      )
    } else if (route.params.product && !route.params.variant) {
      router.push(`/produkter/${route.params.product}`)
    }
  }
}

watch(sessionStatus, async status => {
  if (status === "loggedInSuccessfully" || !isOnline.value) {
    try {
      const { data } = await api.external.get(
        "/rest/frontend/session/user",
      )
      user.value = {
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: data.fullName,
        uid: data.userId,
        email: data.username,
      }
    } catch (exception) {
      console.error(exception)
      return
    }
  }
})

export const getTokenData = (): JWTTokenData | undefined => {
  return getLocalStorageItem("token-data")
}

export function login() {
  return client.authorize()
}

export function logout(redirectPath = "") {
  return client
    .logout()
    .then(() => {
      console.info("Logged out successfully!")
      location.href = `${
        redirectPath ? "/produkter/" + redirectPath : "/"
      }`
    })
    .catch(error => {
      console.error("Failed to log out!")
      console.error(error)
    })
}
// NOTE: All the /selfservice functions will at some point be integrated into the oidc client

export function signup(form: SignupForm) {
  return api.core
    .post("/selfservice/user/registration", {
      ...form,
      username: form.email,
    })
    .then(() => true)
    .catch(error => {
      if (error?.response?.status === 409) throw error
      return false
    })
}

export async function activateUser(form: UserActivationForm) {
  return api.core
    .post("/selfservice/user/activate", form)
    .then(() => login())
    .catch(error => {
      throw error
    })
}

export async function resetPassword(form: ResetPasswordForm) {
  const router = useRouter()

  return api.core
    .post("/selfservice/password/reset", form)
    .catch(error => {
      if (error?.response?.status === 410) {
        router.push("/glemt-passord")
        return
      }

      throw error
    })
}

export async function lostPassword(email: string) {
  return api.core
    .post("/selfservice/password/request-reset", { username: email })
    .catch(error => {
      throw error
    })
}

export function createVippsLoginUrl() {
  const url = new URL("/am/XUI/", import.meta.env.VITE_ID_BASEURL)
  const params = new URLSearchParams({
    service: "vipps_kunne_oidc_tree",
    goto: `https://${import.meta.env.VITE_HOST}/am`,
    gotoOnFail: `${import.meta.env.VITE_ID_CLOUD}/feil`,
  })

  return `${url}?${params.toString()}`
}

export function loginVipps(fromPath?: string) {
  if (!fromPath) {
    fromPath = "/"
  }
  localStorage.setItem("vipps-redirect-from-path", fromPath)

  localStorage.setItem(
    "returning-from-deleting-cookies",
    true.toString(),
  )
  const url = createStandardCookieDeleteUrl()
  location.href = url
}
