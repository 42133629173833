<script lang="ts" setup>
import { PropType } from "vue"

defineProps({
  options: {
    type: Array as PropType<{ value: string; label: string }[]>,
    required: false,
    default: () => [],
  },
  step: {
    type: String,
    required: true,
  },
  showContent: {
    type: Boolean,
    required: true,
  },
  activeStep: {
    type: String,
    required: true,
  },
})

const model = defineModel<string>()
</script>

<template>
  <UStep :is-active-step="activeStep === step">
    <template #step>{{ step }}</template>
    <template #title
      ><label for="variant">Hvor studerer du?</label></template
    >
    <template v-if="showContent" #content>
      <div v-if="options" class="step-1 flex max-w-80 flex-col">
        <select
          id="variant"
          v-model="model"
          class="appearance-none rounded-lg bg-white px-7 py-2 font-national2"
        >
          <option disabled value="">Velg studiested</option>
          <option
            v-for="variant in options"
            :key="variant.value"
            :value="variant.value"
          >
            {{ variant.label }}
          </option>
        </select>
      </div>
      <p v-else>
        Ops, kunne ikke finne produktet! Prøv igjen senere.
      </p>
    </template>
  </UStep>
</template>

<style scoped>
select {
  background-image: url("~/assets/icons/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 1.75rem bottom 0.75rem;
}
</style>
