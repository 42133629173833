<script setup lang="ts">
import { useProductVariantsStore } from "~/store/productVariants"

const products = useProductVariantsStore()
</script>

<template>
  <UHubspotForm
    v-if="
      products.activeProduct?.productKey ===
      'matematikk-for-oekonomer'
    "
    form-url="https://share-eu1.hsforms.com/1wYAJlfMRSNmsFfppFRq5ywfd56p"
    title="Hold deg oppdatert på lanseringen!"
    sub-title="Kunne matematikk for økonomer forventes lansert høsten 2024.
    Registrer deg for å motta oppdateringer og være blant de første som får beskjed når det er tilgjengelig."
  />
</template>

<route lang="yaml">
name: registerInterest
meta:
title: Registrer interesse
</route>
