<script setup lang="ts">
import type { PropType } from "vue"
import { keys } from "~/utilities/injection"

defineProps({
  showSuggestions: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const mathTask = inject(keys.mathTask)
const suggestedSolutionsData = inject(keys.suggestedSolution)
</script>

<template>
  <ol
    v-if="mathTask && mathTask.assignments.length > 1"
    class="flex flex-col gap-4 pl-2 md:pl-5"
  >
    <li
      v-for="assignment in mathTask.assignments"
      :key="assignment.id"
    >
      <UMathTaskAssignment
        :assignment="assignment"
        :show-suggestions="showSuggestions"
      />
    </li>
  </ol>
  <div v-else-if="mathTask?.assignments?.length === 1">
    <UMathTaskAssignment
      :assignment="mathTask?.assignments?.[0]"
      :show-suggestions="showSuggestions"
      :show-assignment-in-suggestion="false"
    />
  </div>
  <div
    v-if="
      showSuggestions &&
      !suggestedSolutionsData?.hasSuggestedSolutions
    "
  >
    <p class="py-6">
      Vi klarte ikke å hente hele løsningsforslaget. Hvis problemet
      vedvarer, kan du kontakte oss via kundeservice.
    </p>

    <UButtonNew
      href="https://om.kunne.universitetsforlaget.no/hjelpesider/kb-tickets/new"
      no-padding-left-and-right
    >
      <template #append>
        <u-icon name="link-external" size="sm" />
      </template>
      Kontakt oss
    </UButtonNew>
  </div>
</template>
