import { postCheckout } from "~/hooks/payment"

export function useCheckout() {
  const termsAccepted = ref(false)

  function submit(
    vippsCheckoutOptions: any,
    productRoute: string,
    EAN: string,
  ): Promise<void> {
    if (!termsAccepted.value)
      return Promise.reject("terms not accepted")

    return postCheckout(productRoute, EAN).then(data => {
      if (data === null) return Promise.reject("no checkout data")

      window.VippsCheckout({
        checkoutFrontendUrl: data.paymentCheckoutFrontendUrl,
        iFrameContainerId: "vipps-checkout-frame-container",
        language: "no",
        token: data.paymentToken,
        ...vippsCheckoutOptions,
      })
    })
  }

  return {
    termsAccepted,
    submit,
  }
}
