<script setup lang="ts">
import type { PropType, Ref } from "vue"

const props = defineProps({
  keyVal: Number as PropType<number>,
  src: {
    type: String as PropType<string>,
    default: "369965",
  },
})
const visible = ref(false) as Ref<boolean>
const iframeHeight = ref() as Ref<number>

onBeforeMount(() => {
  document
    .getElementsByTagName("head")[0]
    .insertAdjacentHTML(
      "beforeend",
      "<link rel=\"stylesheet\" href=\"https://www.riddle.com/embed/files/css/embed.css\" />",
    )

  const riddleScript = document.createElement("script")
  riddleScript.setAttribute(
    "src",
    "https://www.riddle.com/embed/files/js/embed.js",
  )
  document.head.appendChild(riddleScript)
})

document.onload = function () {
  const ridload = document.querySelector("riddle_target")
}

onMountedDOMNode("riddle").then(value => {
  // console.log('value', value)
})

function iframeLoad(loaded: unknown) {
  if (loaded) {
    visible.value = true
  }
}

;(async () => {
  const header = (await onMountedDOMNode(
    "header-main-content-" + props.keyVal,
  )) as HTMLElement
  const footer = (await onMountedDOMNode(
    "footer-main-content-" + props.keyVal,
  )) as HTMLElement
  let headerHeightPX, footerHeightPX

  if (header) headerHeightPX = header.offsetHeight
  if (footer) footerHeightPX = footer.offsetHeight

  if (headerHeightPX && footerHeightPX)
    iframeHeight.value =
      100 -
      convertPXToVH(headerHeightPX) -
      convertPXToVH(footerHeightPX)
})()
</script>

<template>
  <div
    v-show="visible"
    class="w-full"
    :style="`height: ${iframeHeight}vh`"
  >
    <iframe
      id="iframe"
      class="absolute left-0 top-0 h-full w-full overflow-hidden rounded-lg"
      :src="`https://www.riddle.com/embed/a/${src}`"
      @load="iframeLoad"
    />
  </div>
</template>

<style scoped lang="postcss">
iframe {
  @apply bg-red-400;
}
</style>
