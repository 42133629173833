<script setup lang="ts">
import pendo from "~/analytics/pendo"
import { useAuth } from "./composables/auth"
import { setupFeatureFlag } from "~/composables/featureFlag"
import setupNotFoundWathcer from "~/composables/notFound"
import { useApplicationMetaStore } from "~/store/applicationMeta"
import { colors } from "../utils/css/css"
import { useBackgroundColor } from "~/composables/backgroundColor"

setupFeatureFlag()
setupNotFoundWathcer()

const { init, user } = useAuth()
const ui = useUiStore()
const route = useRoute()
const router = useRouter()
const ready = ref(false)
const productRoute = useRoute<"product">()
const subjectRoute = useRoute<"subject">()
const articleRoute = useRoute<"article">()
const productName = computed(() => productRoute.params.product)
const capitalizedProductName = computed(
  () =>
    productName.value[0].toUpperCase() + productName.value.slice(1),
)
const productContent = useProductContentStore()
const productVariants = useProductVariantsStore()
const applicationMeta = useApplicationMetaStore()

applicationMeta.setNewestVersion()
productVariants.hydrateStore()

watchEffect(() => {
  productVariants.product = productRoute.params.product
  productVariants.variant = productRoute.params.variant
  productContent.articleSubjectHref = subjectRoute.params.subject
  productContent.articleHref = articleRoute.params.article
})

init(router).then(async () => {
  const licence = useLicenceStore()
  await licence.setLicenceData()
  ready.value = true
  pendo(route, user.value.uid)
})

// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
const headConfig = {
  title: computed(() => {
    const appName = import.meta.env.VITE_APP_NAME
    const product = productName.value
      ? capitalizedProductName.value.replaceAll("-", " ")
      : ""
    const routeTitle = route.meta.title || ""
    const articleTitle = routeTitle
      ? ""
      : productContent.activeArticle?.title
    const subjectTitle = routeTitle
      ? ""
      : productContent.activeSubject?.title

    return ` ${
      routeTitle ||
      articleTitle ||
      subjectTitle ||
      "Bestå eksamen lettere"
    } - ${appName} ${product}`
  }),
  meta: [
    { name: "description", content: String(route.meta.description) },
  ],
  link: [{ rel: "icon", type: "image/x-icon" }],
}

useHead(headConfig)

const theme = computed(() => {
  if (!productVariants.activeProduct?.theme) {
    return "kunne"
  }

  return productVariants.activeProduct.theme
})

provide("theme", theme)

const themeColors = computed(() => colors[theme.value])
const backgroundColor = useBackgroundColor()

watch(
  () => route.path,
  () => {
    if (productContent.activeArticle?.video) {
      document.body.style.backgroundColor = themeColors.value.dark
    } else if (productContent.activeArticle) {
      document.body.style.backgroundColor =
        "var(--color-platform-paper)"
    } else if (productVariants.activeVariant) {
      document.body.style.backgroundColor = themeColors.value.darkest
    } else if (productVariants.activeProduct) {
      document.body.style.backgroundColor = themeColors.value.lightest
    } else {
      document.body.style.backgroundColor =
        "var(--color-platform-paper)"
    }
  },
  { immediate: true },
)
</script>

<template>
  <!-- Orchestrating async dependencies in a component tree -->
  <!-- Wrap non SSR friendly components for now -->
  <suspense>
    <div id="theme" class="min-h-screen">
      <template v-if="ready">
        <u-toast />
        <router-view />
        <u-pay-result />
        <UAudioPlayer />
        <u-download-media-background />
      </template>
      <u-load-screen v-else :data="ui.loader" />
    </div>
  </suspense>
</template>

<style lang="css" scoped>
#theme {
  --theme: v-bind("theme");
  --theme-lightest: v-bind("themeColors.lightest");
  --theme-lighter: v-bind("themeColors.lighter");
  --theme-light: v-bind("themeColors.light");
  --theme-medium: v-bind("themeColors.medium");
  --theme-dark: v-bind("themeColors.dark");
  --theme-darker: v-bind("themeColors.darker");
  --theme-darkest: v-bind("themeColors.darkest");
  --theme-translucent-light: v-bind(
    "themeColors['translucent-light']"
  );
  --theme-translucent-dark: v-bind("themeColors['translucent-dark']");
  background-color: v-bind("backgroundColor");
}
</style>
 
