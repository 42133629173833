import { acceptHMRUpdate, defineStore } from "pinia"
import { useProductVariantsStore } from "~/store/productVariants"
import { getNewAppVersion } from "~/hooks/external/get-new-app-version"

export const useApplicationMetaStore = defineStore(
  "applicationMeta",
  () => {
    /** State */
    const newestVersion = ref<string | undefined>(undefined)
    const currentVersion = ref<string | undefined>(
      import.meta.env.VITE_APP_VERSION ?? undefined,
    )
    const currentVersionTimestamp = ref<string | undefined>(
      import.meta.env.VITE_APP_VERSION_TIMESTAMP ?? undefined,
    )

    const shouldPromptUpdate = computed(() => {
      if (currentVersionTimestamp.value) {
        const differenceInMs =
          Date.now() - Number(currentVersionTimestamp.value)

        const differenceInDays = differenceInMs / (1000 * 60 * 24)

        return differenceInDays > 3
      } else {
        return false
      }
    })

    /** Actions */
    const setNewestVersion = async () => {
      const data = await getNewAppVersion()
      if (!data) {
        newestVersion.value = undefined
      } else {
        newestVersion.value = data.version
      }
    }

    return {
      /** State */
      newestVersion,
      currentVersion,
      currentVersionTimestamp,
      /** Getters */
      shouldPromptUpdate,
      /** Actions */
      setNewestVersion,
    }
  },
)

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useProductVariantsStore, import.meta.hot),
  )
