import * as Sentry from "@sentry/vue"

type NewAppVersion = { version: string; timestamp: string }

export async function getNewAppVersion(): Promise<
  NewAppVersion | undefined
  > {
  try {
    const data: NewAppVersion = await fetch("/version.json").then(
      res => {
        if (res.ok) {
          return res.json()
        }
        throw new Error("Failed to fetch version.json")
      },
    )

    return data
  } catch (error) {
    Sentry.captureException(error)

    return undefined
  }
}
