import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { createPinia } from "pinia"
import { useCacheStore } from "./store/cache"
import { createHead } from "@vueuse/head"
import VWave from "v-wave"
import { listenForServiceWorkerMessages } from "~/utilities/pwa"

import * as Sentry from "@sentry/vue"
import { datadogRum } from "@datadog/browser-rum"
import { routes } from "vue-router/auto/routes"
import { setupLayouts } from "virtual:generated-layouts"

import App from "./App.vue"

import "./styles/main.css"

export { routes }

import "./scripts"
import { login } from "./utilities/auth"
import { BrowserTracing } from "@sentry/vue"

export const layoutRoutes = setupLayouts(routes)

export const app = createApp(App)

async function start() {
  if (location.pathname === "/am") {
    await login()
  }

  const router = createRouter({
    routes: [
      // Redirects
      {
        path: "/:pathMatch(.*)*",
        redirect: to => {
          console.info(
            "[404] - Redirected because no valid path was found",
          )
          return {
            path: "/404",
            query: {
              from: to.path,
              r: `Vi fant ikke siden du prøvde å nå "${to.path}".`,
            },
          }
        },
      },
      {
        path: "/registrer",
        redirect: to => {
          return {
            path: "/404",
            query: {
              from: to.path,
              r: `Siden du prøvde å nå "${to.path}" har blitt flyttet. Registrering gjøres nå via forsidene våre.`,
            },
          }
        },
      },
      {
        path: "/tilbakestill-passord",
        redirect: to => {
          return {
            path: "/404",
            query: {
              from: to.path,
              r: "Tilbakestilling av passord støttes ikke lenger, og du er nødt til å opprette en bruker gjennom Vipps.",
            },
          }
        },
      },
      {
        path: "/produkter/organisasjonsatferd-og-ledelse/:catchAll(.*)?",
        redirect: to => {
          return {
            path: "/404",
            query: {
              from: to.path,
              r: `Vi fant ikke siden du prøvde å nå "${to.path}".`,
            },
          }
        },
      },
      // File based routing
      ...setupLayouts(routes),
    ],
    history: createWebHistory(),

    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else if (to.hash) {
        return { el: to.hash, top: 300 }
      } else if (to.path !== from.path) {
        return { top: 0 }
      }
    },
  })

  router.beforeEach((to, _, next) => {
    if (typeof document.startViewTransition === "function") {
      document.startViewTransition(() => next())
    } else {
      next()
    }
  })

  const pinia = createPinia()
  app.use(pinia)
  app.use(router)
  const head = createHead()
  app.use(head)
  app.use(VWave, {})
  app.mount("#app")

  useCacheStore()
  listenForServiceWorkerMessages()

  if (/production/.test(import.meta.env.MODE)) {
    Sentry.init({
      app: app,
      dsn: "https://f185c4a89a1c45d7a7f005f2029747c7@o239822.ingest.sentry.io/6147474",
      release: import.meta.env.VITE_APP_VERSION ?? "unknown",

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production

      // Disabled Sentry Replays
      // replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.

      // Disabled Sentry Replays
      // replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.Replay(),
        new BrowserTracing({
          routingInstrumentation:
            Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["kunne.net", "kunne.no", "test.kunne.nu"],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })

    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_CLIENT_TOKEN,
      site: "datadoghq.eu",
      service: "kunne-spa",
      env: "test|stage|prod",
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.SHORT_SHA, // existing incremental version or git short sha
      sessionSampleRate: 10,
      sessionReplaySampleRate: 0, // I need to talk to compliance before enabling this in prod
      trackUserInteractions: false, // I need to talk to compliance before enabling this in prod
      trackResources: false, // I need to talk to compliance before enabling this in prod
      trackLongTasks: false, // I need to talk to compliance before enabling this in prod
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [], // List of aschehoug backends the applications talks directly too, eg ['https://external-api.core.test.aschehoug.cloud', 'https://internal-api.core.test.aschehoug.cloud'] without it the datadog trace id will not be set on requests to these backends
      startSessionReplayRecordingManually: true,
    })
    console.info("Datadog for prod env initialized")
  } else if (!location.hostname.includes("localhost")) {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_CLIENT_TOKEN,
      site: "datadoghq.eu",
      service: "kunne-spa",
      env: "test|stage|prod",
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.SHORT_SHA, // existing incremental version or git short sha
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [], // List of aschehoug backends the applications talks directly too, eg ['https://external-api.core.test.aschehoug.cloud', 'https://internal-api.core.test.aschehoug.cloud'] without it the datadog trace id will not be set on requests to these backends
      startSessionReplayRecordingManually: false,
    })
    console.info("Datadog for test env initialized")
  }
}

start()
